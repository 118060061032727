import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Subscription } from 'rxjs';

// const ITEMS_PER_PAGE = 'common.paginator.itemsPerPageLabel'; // Elemek száma egy oldalon
// const NEXT_PAGE = 'common.paginator.nextPageLabel'; // Következő oldal
// const PREV_PAGE = 'common.paginator.previousPageLabel'; // Előző oldal
// const FIRST_PAGE = 'common.paginator.firstPageLabel'; // Első oldal
// const LAST_PAGE = 'common.paginator.lastPageLabel'; // Utolsó oldal
const ITEMS_PER_PAGE = 'Elemek száma egy oldalon'; // Elemek száma egy oldalon
const NEXT_PAGE = 'Következő oldal'; // Következő oldal
const PREV_PAGE = 'Előző oldal'; // Előző oldal
const FIRST_PAGE = 'Első oldal'; // Első oldal
const LAST_PAGE = 'Utolsó oldal'; // Utolsó oldal

@Injectable()
export class CustomPaginator extends MatPaginatorIntl {
  private _subscription: Subscription;
  
  public constructor(
    private _translate: TranslateService
  ) {
    super();

    this._subscription = this._translate.onLangChange.subscribe((e: Event) => {
      this.getAndInitTranslations();
    });
    this.getAndInitTranslations();
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public override getRangeLabel = (
    page: number,
    pageSize: number,
    length: number
  ): string => {
    if (length === 0 || pageSize === 0) {
      return `0 / ${length}`;
    }

    length = Math.max(length, 0);

    const startIndex: number = page * pageSize;
    const endIndex: number =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} / ${length}`;
  };

  public getAndInitTranslations(): void {
    this._translate
      .get([ITEMS_PER_PAGE, NEXT_PAGE, PREV_PAGE, FIRST_PAGE, LAST_PAGE])
      .subscribe((translation: any) => {
        this.itemsPerPageLabel = translation[ITEMS_PER_PAGE];
        this.nextPageLabel = translation[NEXT_PAGE];
        this.previousPageLabel = translation[PREV_PAGE];
        this.firstPageLabel = translation[FIRST_PAGE];
        this.lastPageLabel = translation[LAST_PAGE];

        this.changes.next();
      });
  }
}
