<div id="scrollTarget"></div>
<div [id]="messageId"></div>
<ng-container *ngIf="message$ | async as message">
  <div
    *ngIf="message.messageId === messageId"
    id="message-container"
    class="alert mb-3 mt-0 message-container"
    [ngClass]="{
      'alert-success': message.severity === MessageSeverity.SUCCESS,
      'alert-info': message.severity === MessageSeverity.INFO,
      'alert-warning': message.severity === MessageSeverity.WARNING,
      'alert-danger': message.severity === MessageSeverity.DANGER,
      'alert-primary': message.severity === MessageSeverity.PRIMARY,
      'alert-secondary': message.severity === MessageSeverity.SECONDARY,
      'alert-dark': message.severity === MessageSeverity.DARK || message.severity === undefined,
    }"
    role="alert"
  >
    <!-- <strong *ngIf="message.title">{{message.title}} </strong>{{message.message}} -->


    <div [ngClass]="{'message-dismissable': message.dismissable}" class="message">
      <div>
        <strong *ngIf="message.title">{{message.title}} </strong><span role="text" [innerHtml]="message.message | safeHtmlPipe"></span>
        <!-- <strong *ngIf="message.title">{{message.title}} </strong><span role="text" [innerHtml]="message.message"></span> -->
        <!-- <strong *ngIf="message.title">{{message.title}} </strong><span role="text">{{ message.message | safeHtmlPipe }}</span> -->
      </div>
      <div *ngIf="message.subMessage" class="sub-message">
        <span role="text" [innerHtml]="message.subMessage"></span>
      </div>
    </div>


    <div *ngIf="message.dismissable" class="dismissable">
      <button mat-icon-button (click)="onDismiss($event)"><mat-icon>highlight_off</mat-icon></button>
    </div>

  </div>
</ng-container>
