import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    private readonly _loaderVisible = new BehaviorSubject<boolean>(false);
    loaderVisible$ = this._loaderVisible.asObservable();

    private _loadingMap: Map<string, boolean> = new Map<string, boolean>();

    constructor() {}

    /**
     * Sets the _loaderVisible property value based on the following:
     * - If loading is true, add the provided url to the _loadingMap with a true value, set _loaderVisible value to true
     * - If loading is false, remove the _loadingMap entry and only when the map is empty will we set _loaderVisible to false
     * This pattern ensures if there are multiple requests awaiting completion, we don't set loading to false before
     * other requests have completed. At the moment, this function is only called from the @link{HttpRequestInterceptor}
     * @param loading {boolean}
     * @param url {string}
     */
    setLoading(loading: boolean, url: string): void {
        if (!url) {
            throw new Error('The request URL must be provided to the LoadingService.setLoading function');
        }
        if (loading === true) {
            this._loadingMap.set(url, loading);
            this._loaderVisible.next(true);
        }
        else if (loading === false && this._loadingMap.has(url)) {
            this._loadingMap.delete(url);
        }
    
        if (this._loadingMap.size === 0) {
            this._loaderVisible.next(false);
        }
    }

    get loaderVisible(): boolean {
        return this._loaderVisible.getValue();
    }
}