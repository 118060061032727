import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Message } from './../models/message.model';
import { MessageSeverity } from '../enums/message-severity.enum';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class MessageService {
    private _message = new BehaviorSubject<Message | null>(null);
    message$ = this._message.asObservable();

    constructor(
        private _router: Router,
    ) {}

    add(message: Message | null): void {
        this._message.next(message);
    }

    clear(id?: string): void {
        const messageValue = this._message.getValue();
        if(messageValue && messageValue.targetUrl && messageValue.targetUrl === this.getActualPath()){
            return;
        }

        if(id === undefined || (id && messageValue && id === messageValue.messageId)){
            this._message.next(null);
        }
    }

    addDangerMessage(messageText: string, id: string, dismissable = false, targetUrl?: string): void {
        this.add({
            message: messageText,
            messageId: id,
            severity: MessageSeverity.DANGER,
            dismissable,
            targetUrl,
        } as Message);
    }

    addSuccessMessage(messageText: string, id: string, dismissable = false, targetUrl?: string): void {
        this.add({
            message: messageText,
            messageId: id,
            severity: MessageSeverity.SUCCESS,
            dismissable,
            targetUrl,
        } as Message);
    }

    addInfoMessage(messageText: string, id: string, dismissable = false, targetUrl?: string): void {
        this.add({
            message: messageText,
            messageId: id,
            severity: MessageSeverity.INFO,
            dismissable,
            targetUrl,
        } as Message);
    }

    getActualPath(): string {
        const urlTree = this._router.parseUrl(this._router.url);
        urlTree.queryParams = {}; 
        urlTree.fragment = null; // optional
        return urlTree.toString();
    }

}
