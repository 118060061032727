import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'safeHtmlPipe',
    standalone: true,
})
export class SafeHtmlPipe implements PipeTransform {
    constructor(
        private readonly _domSanitizer: DomSanitizer,
    ) {}

    transform(value: any) {
        if(value){
            // NOTE: Consider using DomSanitizer#sanitize instead of DomSanitizer#bypassSecurityTrustHtml, which executes code in `<script>` tags
            return this._domSanitizer.sanitize(SecurityContext.HTML, this.escapeHtml(value));
        }
        return value;
    }

    escapeHtml(unsafe: any) {
        if (unsafe) {
            return unsafe.replace(/(<\/?(?:style|p|a|img)[^>]*>)/ig, '');
        }
        return unsafe;
    }
}
