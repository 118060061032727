import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription, of } from 'rxjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { NgIf, AsyncPipe, CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { FeatherModule } from 'angular-feather';

@Component({
    selector: 'app-spinner-button',
    templateUrl: './spinner-button.component.html',
    styleUrls: ['./spinner-button.component.scss'],
    standalone: true,
    imports: [
        MatButtonModule,
        NgIf,
        MatIconModule,
        MatProgressSpinnerModule,
        AsyncPipe,
        CommonModule,
        FeatherModule,
    ],
})
export class SpinnerButtonComponent implements OnInit, OnDestroy {
    @Input() buttonColor = 'primary';
    @Input() buttonClass = '';
    @Input() buttonType = 'submit';
    @Input() buttonDisabled = false;
    @Input() loading$: Observable<boolean> = of(false);
    @Input() featherIcon?: string;
    @Input() fontAwesomeIcon?: string;
    @Input() clickAutoSpinner = true;
    @Output() clicked = new EventEmitter<Event>();

    private _subscription: Subscription = new Subscription();

    isSpinner = false;

    constructor() {}

    ngOnInit() {
        this.createSubscriptions();
    }

    ngOnDestroy() {
        this._subscription.unsubscribe();
    }

    private createSubscriptions(): void {
        this._subscription.add(
            this.loading$.subscribe({
                next: (loading: boolean) => {
                  if(!loading){
                    this.isSpinner = false;
                  }
                }
            })
        );
    }

    onClick(e: Event): void{
        if(this.clickAutoSpinner){
            this.isSpinner = true;
        }
        this.clicked.emit(e);
        e.preventDefault();
    }

    onSubmit(e: Event): void{
        if(this.clickAutoSpinner){
            this.isSpinner = true;
        }
    }
}
