import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'missingDataText',
    standalone: true,
})
export class MissingDataTextPipe implements PipeTransform {
    constructor() {}

    transform(value: any, isZero: boolean = false) {
        if(value == null || value === undefined){
            if(isZero){
                return '0';
            }
            return `-`;
        }
        return value;
    }
}
