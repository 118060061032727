<ng-template
  #t
  [ngTemplateOutlet]="t"
  let-loading
  [ngTemplateOutletContext]="{
    $implicit: (loading$ | async)
  }"
>

@if(buttonType === 'submit'){
    <button mat-raised-button [color]="buttonColor" [class]="buttonClass" [type]="buttonType" [disabled]="loading || buttonDisabled" (click)="onSubmit($event)">
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </button>
}
@else {
    <button mat-raised-button [color]="buttonColor" [class]="buttonClass" [type]="buttonType" [disabled]="loading || buttonDisabled" (click)="onClick($event)">
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </button>
}

<ng-template #content>
    <mat-icon *ngIf="isSpinner && clickAutoSpinner"><mat-spinner diameter="18" [color]="buttonColor"></mat-spinner></mat-icon>

    <ng-container *ngIf="!loading || !isSpinner">

        <ng-container *ngIf="featherIcon">
            <i-feather [name]="featherIcon" class="feather-icon"></i-feather>
        </ng-container>

        <ng-container *ngIf="fontAwesomeIcon">
            <i [class]="fontAwesomeIcon"></i>
        </ng-container>

    </ng-container>

    <ng-content></ng-content>
</ng-template>
