import { Component } from '@angular/core';
import { LoaderService } from '../../../core/service/loader.service';
import { SafeHtmlPipe } from '../../pipes/safe-html.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgIf, NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        NgTemplateOutlet,
        MatProgressBarModule,
        MatButtonModule,
        MatIconModule,
        AsyncPipe,
        SafeHtmlPipe,
        MatProgressSpinner,
    ],
})
export class SpinnerComponent {
    constructor(
        public loaderService: LoaderService,
    ) {}
}