import { Component, Input, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { MessageSeverity } from '../../../core/enums/message-severity.enum';
import { Message } from '../../../core/models/message.model';
import { MessageService } from '../../../core/service/message.service';
import { SafeHtmlPipe } from '../../pipes/safe-html.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgClass, AsyncPipe } from '@angular/common';
import { ViewportScroller } from "@angular/common";

@Component({
    selector: 'app-message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        MatButtonModule,
        MatIconModule,
        AsyncPipe,
        SafeHtmlPipe,
    ],
})
export class MessageComponent implements OnDestroy {
    @Input() messageId?: string;

    message$: Observable<Message | null>;
    MessageSeverity = MessageSeverity;

    private _subscriptions: Subscription = new Subscription();

    constructor(
        private readonly _messageService: MessageService,
        private readonly _scroller: ViewportScroller,
    ) {
        this.message$ = this._messageService.message$;
        this._subscriptions.add(
            this.message$.subscribe({
                next: (message: Message | null) => {
                    if(message != null && this.messageId && this.messageId === message.messageId){
                        setTimeout(() => {
                            // this._scroller.setHistoryScrollRestoration('manual');
                            this._scroller.setOffset([0,65]);
                            this._scroller.scrollToAnchor(this.messageId ?? 'scrollTarget');
                            // console.dir('scrolled to: ' + this.messageId ?? 'scrollTarget');
                        }, 500);
                    }
                }
            })
        );
    }

    ngOnDestroy(): void{
        this._subscriptions.unsubscribe();
        this.clear(this.messageId);
    }

    onDismiss(e: Event): void {
        this.clear(this.messageId);
        e.preventDefault();
    }

    private clear(messageId?: string): void{
        this._messageService.clear(messageId);
    }
}
