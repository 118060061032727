<div *ngIf="loaderService.loaderVisible$ | async" class="overlay-container">
    <div class="loading">
        <!-- <mat-spinner></mat-spinner> -->

        <svg class="custom-mat-spinner" viewBox="0 0 50 50">
            <circle class="custom-mat-spinner-path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
        </svg>

    </div>
</div>
