import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MaterialModule } from './material.module';
import { MessageComponent } from './components/message/message.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { FeatherIconsModule } from './components/feather-icons/feather-icons.module';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomPaginator } from '../core/service/custom-paginator';
import { SpinnerButtonComponent } from './components/spinner-button/spinner-button.component';
import { MissingDataTextPipe } from './pipes/missing-data-text.pipe';
import { SpinnerComponent } from './components/spinner/spinner.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MaterialModule,
        MessageComponent,
        SafeHtmlPipe,
        SpinnerButtonComponent,
        MissingDataTextPipe,
        SpinnerComponent
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MaterialModule,
        MessageComponent,
        SafeHtmlPipe,
        FeatherIconsModule,
        SpinnerButtonComponent,
        MissingDataTextPipe,
        SpinnerComponent,
    ],
    providers: [
        {
            provide: MatPaginatorIntl,
            useClass: CustomPaginator,
        },
    ],
})
export class SharedModule {}
