import { StorageType } from '../core/enums/storage-type.enum';

export const config = {
  // session
  session: {
    defaultStorageType: StorageType.SESSION,
    listStateSearchStorageName: 'searchRequest',
    listStateSelectionStorageName: 'selection',
    multisortSettingsStorageName: 'multisortSettings',
  },
  // validator
  validator: {
    email: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
    // password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/,
    login: /^[a-zA-Z0-9\-@\.]{5,30}$/,
    phone: /^(((\+)|(00))[0-9]{8,14})|(06[0-9]{1,2}[0-9]{6,7})$/,
  },
  // template
  layout: {
    rtl: false, // options:  true & false
    variant: 'light', // options:  light & dark
    theme_color: 'white', // options:  white, black, purple, blue, cyan, green, orange
    logo_bg_color: 'white', // options:  white, black, purple, blue, cyan, green, orange
    sidebar: {
      collapsed: false, // options:  true & false
      backgroundColor: 'light', // options:  light & dark
    },
  },
};
